'use client';
import { Box, Paper, Chip } from '@mui/material';
import { isFilled } from '@prismicio/client';
import CustomPrismicText from '../../components/prismic/customPrismicText';
import BackgroundImage from '../prismic/backgroundImage';
import { motion } from 'framer-motion';

const styles = {
  timelineText: {
    textAlign: 'left',
    padding: 0,
    backgroundColor: 'white',
    minHeight: '400px',
    overflowX: 'hidden',
  },
  itemNameBox: {
    marginBottom: '20px',
  },
  itemDescBox: {
    listStyleType: 'disc',
  },
  team: {
    marginTop: '20px',
  },
  technologies: {
    marginTop: '20px',
  },
  chipsBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
  },
  chip: {
    minWidth: '70px',
  },
  timelineRow: {
    display: 'flex',
    flexDirection: { xs: 'column', lg: 'row' },
    height: '100%',
  },
  imgBlock: {
    position: 'relative',
    width: { xs: '100%', lg: '40%' },
    height: { xs: '300px', md: '500px', lg: '100%' },
  },
  textBlock: {
    padding: { xs: '30px', sm: '30px 50px', md: '50px' },
    width: { xs: '100%', lg: '60%' },
  },
};

const TimelineItemContent = ({
  image,
  itemName,
  itemDescription,
  technologies,
  team,
}) => {
  return (
    <Paper
      component={motion.div}
      elevation={3}
      sx={styles.timelineText}
      initial={{
        opacity: 0,
        y: 100,
      }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      viewport={{
        once: true,
      }}
      transition={{
        ease: 'easeInOut',
        duration: 1,
      }}>
      <Box sx={styles.timelineRow}>
        <Box sx={styles.imgBlock}>
          <BackgroundImage field={image} zIndex='0' />
        </Box>
        <Box sx={styles.textBlock}>
          {isFilled.richText(itemName) && (
            <Box sx={styles.itemNameBox}>
              <CustomPrismicText field={itemName} component='h3' />
            </Box>
          )}
          {isFilled.richText(itemDescription) && (
            <Box sx={styles.itemDescBox}>
              <CustomPrismicText field={itemDescription} />
            </Box>
          )}
          {isFilled.richText(team) && (
            <Box sx={styles.team}>
              <CustomPrismicText field={team} />
            </Box>
          )}
          {isFilled.richText(technologies) && (
            <Box sx={styles.technologies}>
              <CustomPrismicText
                field={technologies}
                components={{
                  listItem: ({ children, key }) => (
                    <Chip
                      key={key}
                      variant='outlined'
                      label={children}
                      sx={styles.chip}
                    />
                  ),
                  list: ({ children, key }) => {
                    return (
                      <Box key={key} sx={styles.chipsBlock}>
                        {children}
                      </Box>
                    );
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default TimelineItemContent;
