import { Box } from '@mui/material';
import { isFilled } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';

const styles = {
  bgImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: '70% 80%',
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'secondary.main',
  },
};

const BackgroundImage = ({ field, zIndex = '-1', ...otherProps }) => {
  return (
    <>
      {/* mobile SSR*/}
      {isFilled.image(field.mobile) && (
        <Box
          component={PrismicNextImage}
          field={field.mobile}
          sx={{
            ...styles.bgImg,
            zIndex: zIndex,
            display: { xs: 'block', md: 'none' },
          }}
          {...otherProps}
        />
      )}

      {/* desktop SSR*/}
      <Box
        component={PrismicNextImage}
        field={field}
        sx={{
          ...styles.bgImg,
          zIndex: zIndex,
          display: {
            xs: isFilled.image(field.mobile) ? 'none' : 'block',
            md: 'block',
          },
        }}
        {...otherProps}
      />
    </>
  );
};

export default BackgroundImage;
