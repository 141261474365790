import { PrismicRichText } from '@prismicio/react';
import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import CodeComponent from '../blog/codeComponent';
import { PrismicNextImage, PrismicNextLink } from '@prismicio/next';

const styles = {
  default: {
    '& em': {
      color: 'primary.main',
      fontStyle: 'unset',
      textShadow: '1px 1px 1px black',
    },
  },
  h2: {
    marginBottom: '40px',
  },
  imgBlock: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      maxWidth: '80%',
      height: 'auto',
    },
  },
  quoteBlock: {
    margin: 0,
    background: '#eee',
    padding: '1em',
    borderRadius: '1em',
    fontStyle: 'italic',
  },
};

const CustomPrismicText = ({
  field,
  variant = null,
  component = null,
  sx = {},
  components = {},
  ...props
}) => {
  return (
    <PrismicRichText
      field={field}
      components={{
        heading1: ({ children }) => (
          <Typography
            variant={variant || 'h1'}
            component={component || 'h1'}
            sx={{ ...styles.default, ...sx }}
            {...props}>
            {children}
          </Typography>
        ),
        heading2: ({ children }) => (
          <Typography
            variant={variant || 'h2'}
            component={component || 'h2'}
            sx={{ ...styles.default, ...styles.h2, ...sx }}
            {...props}>
            {children}
          </Typography>
        ),
        heading3: ({ children }) => (
          <Typography
            variant={variant || 'h3'}
            component={component || 'h3'}
            sx={{ ...styles.default, ...styles.h3, ...sx }}
            {...props}>
            {children}
          </Typography>
        ),
        heading4: ({ children }) => (
          <Typography
            variant={variant || 'h4'}
            component={component || 'h4'}
            sx={{ ...styles.default, ...styles.h3, ...sx }}
            {...props}>
            {children}
          </Typography>
        ),
        heading5: ({ children }) => (
          <Typography
            variant={variant || 'h5'}
            component={component || 'h5'}
            sx={{ ...styles.default, ...styles.h3, ...sx }}
            {...props}>
            {children}
          </Typography>
        ),
        // use only for blockquotes
        heading6: ({ children }) => (
          <Box component='figure' sx={styles.quoteBlock} {...props}>
            <Box component='blockquote' sx={styles.quote}>
              <Typography variant={'body1'} component={'p'} sx={styles.default}>
                {children}
              </Typography>
            </Box>
          </Box>
        ),
        paragraph: ({ children }) => (
          <Typography
            variant={variant || 'body1'}
            component={component || 'p'}
            sx={{
              ...styles.default,
              paddingTop: '3px',
              paddingBottom: '3px',
              ...sx,
            }}
            {...props}>
            {children}
          </Typography>
        ),
        preformatted: ({ node }) => <CodeComponent code={node.text} />,
        image: ({ node }) => (
          <Box component={component || 'div'} sx={styles.imgBlock} {...props}>
            <PrismicNextImage field={node} />
          </Box>
        ),
        hyperlink: ({ node, children }) => {
          let hrefWithAnchorTag = null;
          if (
            node.data.link_type === 'Web' &&
            node.data.url.indexOf('#') > -1
          ) {
            const { url } = node.data;
            const urlNoProtocol = url.replace(/^https?\:\/\//i, '');
            const anchorTag = urlNoProtocol.substring(
              urlNoProtocol.indexOf('#'),
              urlNoProtocol.length
            );
            if (urlNoProtocol.startsWith(anchorTag)) {
              hrefWithAnchorTag = anchorTag;
            }
          }

          return (
            <PrismicNextLink
              {...(hrefWithAnchorTag
                ? { href: hrefWithAnchorTag }
                : { field: node.data })}
              style={{ textDecoration: 'underline' }}>
              {children}
            </PrismicNextLink>
          );
        },

        ...components,
      }}
    />
  );
};

CustomPrismicText.propTypes = {
  field: PropTypes.array.isRequired,
  variant: PropTypes.string,
  sx: PropTypes.object,
};

export default CustomPrismicText;
