'use client';
import PropTypes from 'prop-types';
import { Box, Button, useMediaQuery } from '@mui/material';
import {
  TimelineOppositeContent,
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab';
import Link from 'next/link';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import { asText } from '@prismicio/client';
import CustomPrismicText from '../../components/prismic/customPrismicText';
import TimelineItemContent from '@/components/timelineSlice/timelineItemContent';

/**
 * @typedef {import("@prismicio/client").Content.TimelineSliceSlice} TimelineSliceSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TimelineSliceSlice>} TimelineSliceProps
 * @param {TimelineSliceProps}
 */

const styles = {
  section: {
    position: 'relative',
    padding: { xs: '48px 18px ', sm: '150px 96px 150px' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    maxWidth: '1248px',
    margin: { xs: 'unset', md: '0 auto' },
    width: '100%',
    position: 'relative',
    scrollBehavior: 'smooth',
  },
  titleBlock: {
    marginBottom: '50px',
    textAlign: 'center',
  },
  timelineContent: {
    paddingTop: '50px',
    paddingBottom: '50px',
  },
  when: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '300px',
  },
  timelineDot: {
    backgroundColor: 'primary.main',
    borderColor: 'white',
  },
  btnContainer: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'center',
  },
  mobileContainer: {
    display: 'grid',
    gap: { xs: '30px', md: '50px' },
  },
  descriptionBlock: {
    maxWidth: { xs: 'unset', md: '80%' },
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

const TimelineSlice = ({ slice }) => {
  const {
    primary: {
      slice_title: title,
      slice_desription: description,
      background_color: bgColor,
      button_text: btnText,
    },
    items = [],
  } = slice;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  if (slice.variation === 'withImage') {
    return (
      <Box
        id='projects'
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        component='section'
        sx={{
          ...styles.section,
          backgroundColor: bgColor || '#fafafa',
        }}>
        <Box sx={styles.container}>
          <Box sx={styles.titleBlock}>
            <CustomPrismicText field={title} />
            <Box sx={styles.descriptionBlock}>
              <CustomPrismicText field={description} />
            </Box>
          </Box>

          {isMobile ? (
            <Box sx={styles.mobileContainer}>
              {items.map(
                ({
                  item_name: itemName,
                  item_description: itemDescription,
                  when,
                  technologies,
                  team,
                }) => {
                  return (
                    <TimelineItemContent
                      key={asText(itemName)}
                      itemName={itemName}
                      itemDescription={itemDescription}
                      technologies={technologies}
                      team={team}
                    />
                  );
                }
              )}
            </Box>
          ) : (
            <Timeline>
              {items.map(
                ({
                  item_name: itemName,
                  item_description: itemDescription,
                  when,
                  technologies,
                  team,
                }) => {
                  return (
                    <TimelineItem key={asText(itemName)}>
                      <TimelineOppositeContent sx={styles.when}>
                        <CustomPrismicText field={when} />
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot sx={styles.timelineDot}>
                          <LaptopMacIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={styles.timelineContent}>
                        <TimelineItemContent
                          itemName={itemName}
                          itemDescription={itemDescription}
                          technologies={technologies}
                          team={team}
                        />
                      </TimelineContent>
                    </TimelineItem>
                  );
                }
              )}
            </Timeline>
          )}

          <Box sx={styles.btnContainer}>
            <Button variant='contained' component={Link} href='#contact'>
              <CustomPrismicText field={btnText} />
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      id='projects'
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      component='section'
      sx={{
        ...styles.section,
        backgroundColor: bgColor || '#fafafa',
      }}>
      <Box sx={styles.container}>
        <Box sx={styles.titleBlock}>
          <CustomPrismicText field={title} />
          <Box sx={styles.descriptionBlock}>
            <CustomPrismicText field={description} />
          </Box>
        </Box>
        <Box sx={styles.mobileContainer}>
          {items.map(
            ({
              image,
              item_name: itemName,
              item_description: itemDescription,
              technologies,
              team,
            }) => {
              return (
                <TimelineItemContent
                  key={asText(itemName)}
                  image={image}
                  itemName={itemName}
                  itemDescription={itemDescription}
                  technologies={technologies}
                  team={team}
                />
              );
            }
          )}
        </Box>
      </Box>
    </Box>
  );
};

TimelineSlice.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default TimelineSlice;
